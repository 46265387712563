import React from 'react';
import {Helmet} from "react-helmet";
import alchemy from '../images/ramp/alchemy-pay-ach-logo.png';
import bcpay from '../images/ramp/bc-logo.png';
import mercuryo from '../images/ramp/mercuryo-logo.jpg';
import moonpay from '../images/ramp/moonpay-logo.png';
import transak from '../images/ramp/transak-logo.png';

const OnOffRamp = ()=>{
    const projects = [
        {
            url:'https://www.moonpay.com/buy',
            title:'Moonpay',
            description:"A fintech company offering infrastructure for crypto payments. It enables conversion between fiat and cryptocurrencies using various payment methods, operating in over 160 countries. It's known for integrating local compliance, payment methods, and liquidity globally.",
            icon:moonpay
        },
        {
            url:'https://alchemypay.org/',
            title:'Alchemy Pay',
            description:"A payment solution provider that connects fiat and crypto economies. It offers services to merchants for accepting both fiat and crypto and facilitates easy blockchain ecosystem onboarding. Operates in over 70 countries with 300 payment channels.",
            icon:alchemy
        },
        {
            url:'https://transak.com/',
            title:'Transak',
            description:"A developer-focused fiat-to-crypto payment gateway. It allows mainstream access to crypto by integrating local compliance and various payment methods globally.",
            icon:transak
        },
        {
            url:'https://mercuryo.io/crypto-in-out/',
            title:'Mercuryo',
            description:"A fintech platform bridging fiat and crypto worlds for global businesses. It provides services like payment processing, fiat and crypto exchange, and regulation compliance. Tailors its B2B payment toolbox to specific customer needs.",
            icon:mercuryo
        },
        {
            url:'https://trondao.org/buy-trx',
            title:'Blockchain.com Pay',
            description:"A B2B crypto on- and off-ramp service for easy crypto transactions. Offers integration of fiat on- and off-ramps in apps, backed by Blockchain.com's infrastructure and KYC-verified user base. Focuses on compliance and streamlined onboarding.",
            icon:bcpay
        }
    ];
    return (
        <div className='on-off-ramp'>
            <Helmet>
                <title>TRON on off ramp | TRONDAO</title>
                <meta property="og:title" content="TRON on off ramp | TRONDAO"/>
            </Helmet>
            <div className='on-off-ramp-header'>
                <h2>On & Off Ramp on TRC Chain</h2>
                <p>TRON is not responsible for transactions which occur on third-party, external platforms. Users are advised to independently research and  select their on/off ramp services carefully, considering each platform's unique KYC (know-your-customer)  policies and authorized jurisdictions of operation. TRON is not responsible for transactions conducted on external platforms and shall have no liability for loss, fraud or damages related to  third-party services.</p>
            </div>
            <div className="d-flex flex-wrap pb-5 on-off-ramp-container-lower justify-content-between">
                {
                    projects.length > 0 && projects.map(system => {
                        return <a href={system.url} className="on-off-ramp-card px-3" key={system.title}
                                  target="_blank" rel="noopener noreferrer" id={'on-off-ramp-card-' + system.title}>
                            {/* <div className="w-100">
                                <span
                                    className={'on-off-ramp-card-category'}>{system.node.dappinfo.category}</span>
                                {
                                    system.node.dappinfo.isnew === true &&
                                    <span className="on-off-ramp-card-new float-right">NEW!</span>

                                }
                            </div> */}
                            <div className="text-center w-100">
                                <img src={system.icon} alt={system.title + ' logo'}
                                     className="on-off-ramp-card-image"/>
                            </div>
                            <div className="text-center row no-gutters">
                                <h2 className="w-100 on-off-ramp-card-title">{system.title}</h2>
                                <p className="on-off-ramp-card-description">
                                    {system.description}
                                </p>
                            </div>

                        </a>
                    })
                }
            </div>
        </div>
    )
}
export default OnOffRamp